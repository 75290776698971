<template>
	<v-container fluid>
		<v-card v-if="!loading">
			<v-card-title class="pt-6 pb-0 px-6">
				<span class="headline font-weight-light mb-5">Edit Answer</span>
			</v-card-title>
			<v-card-text class="px-6">
				<v-row no-gutters>
					<v-col cols="12" class="mb-5">
						<p class="ma-0 label">Question* :</p>
						<h3 class="pt-2">{{ question }}</h3>
					</v-col>
					<v-col cols="6" class="d-flex flex-row">
						<p class="mb-2 pr-2 label">Questioner Email:</p>
						<p>
							{{
								questioner.email
									? questioner.email
									: 'Not Available'
							}}
						</p>
					</v-col>
					<v-col cols="6" class="d-flex flex-row">
						<p class="mb-2 pr-2 label">Questioner Phone Number:</p>
						<p>
							{{
								questioner.phoneNumber
									? questioner.phoneNumber
									: 'Not Available'
							}}
						</p>
					</v-col>
					<v-col cols="12">
						<p class="mb-1 label">Services:</p>
						<Search
							:initialData="services"
							:url="`/${$store.state.app.projectId}/services/search`"
							@search-result="updateService($event)"
						></Search>
					</v-col>
					<v-col cols="12">
						<p class="mt-2 label mb-5">Answer:</p>
						<div id="ckcontent">
							<Editor
								:purpose="`create faq`"
								@changed="editorChanged"
								:initData="answer"
							></Editor>
							<span
								class="error-msg"
								v-for="(error, ind) in answerErrors()"
								:key="ind"
							>
								{{ error }}
							</span>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions class="pa-6 card-actions">
				<UnoSaveButton
					@click.native="saveData()"
					@primary-button-click="questionAnswerListing()"
					primary-button-text="Move To Question Listing"
					secondary-button-text="Continue Editing"
					:alert="alert"
					:saving="isSaving"
				></UnoSaveButton>
				<UnoPublishButton
					@click.native="toggleStatus()"
					domain="question-answer"
					:id="id"
					:isPublished="isPublished"
				></UnoPublishButton>
				<v-spacer></v-spacer>
				<UnoDeleteButton @confirm="deleteQuestion()"></UnoDeleteButton>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import Search from '@/components/Search'

import { required, minLength } from 'vuelidate/lib/validators'
// ACTION BUTTONS
import UnoPublishButton from '@/components/Buttons/UnoPublishButton'
import UnoSaveButton from '@/components/Buttons/UnoSaveButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'

import Editor from '@/components/CkEditor'

export default {
	components: {
		Editor,
		Search,
		UnoSaveButton,
		UnoDeleteButton,
		UnoPublishButton,
	},
	props: {
		openSidebar: {
			type: Boolean,
		},
		id: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isSaving: false,
			loading: true,
			questioner: null,
			questionId: null,
			isPublished: true,
			question: '',
			editedOn: null,
			answeredOn: null,
			answer: '',
			services: [],
			errors: {
				question: '',
				answer: '',
			},
			alert: {
				show: null,
				message: null,
				error: false,
			},
		}
	},
	validations: {
		answer: {
			required,
			minLength: minLength(10),
		},
	},
	watch: {
		answer() {
			this.errors.answer = ''
		},
	},
	created() {
		if (this.id) {
			this.questionId = this.id
			this.getQuestionAnswer()
		} else {
			this.loading = false
		}
	},
	methods: {
		answerErrors() {
			const errors = []
			if (!this.$v.answer.$dirty) return errors

			!this.$v.answer.minLength &&
				errors.push('Answer must be atleast 10 letters long')
			!this.$v.answer.required && errors.push('Answer is required.')

			return errors
		},
		editorChanged(data) {
			this.$v.answer.$touch()
			this.answer = data
		},
		setQuestionAnswerData(data) {
			this.questionId = data.id
			this.questioner = data.questioner
			this.services = data.services
			this.question = data.question
			this.editedOn = data.editedOn
			this.answeredOn = data.answeredOn
			this.answer = data.answer
			this.isPublished = data.isPublished
			this.loading = false
		},
		saveData() {
			this.isSaving = true
			const projectId = this.$store.state.app.projectId

			const payload = {
				answer: this.answer,
				editedOn: new Date(),
				answeredOn: this.answeredOn || new Date(),

				services: this.services.map((obj) => {
					return obj['id'] ? obj['id'] : obj
				}),
			}

			this.axios({
				method: 'put',
				url: `/${projectId}/question-answer/answer/${this.questionId}`,
				data: payload,
			})
				.then((response) => {
					this.isSaving = false

					if (response.status == 200 && response.data.success) {
						const data = response.data.data
						this.setQuestionAnswerData(data)
						this.alert.message = response.data.message
					} else {
						//
					}
				})
				.catch((error) => {
					// let errors = error.response.data
					// this.alert.message = errors.message
					console.log(error)
					this.isSaving = false
				})
		},
		getQuestionAnswer() {
			this.isSaving = true

			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/question-answer/${this.id}`,
			}).then((response) => {
				let data = response.data.data
				this.setQuestionAnswerData(data)
				this.isSaving = false
			})
		},
		deleteQuestion() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'delete',
				url: `/${projectId}/question-answer/${this.id}`,
			}).then(() => {
				this.questionAnswerListing()
			})
		},
		questionAnswerListing() {
			this.$router.push({
				name: 'question-answer.list',
			})
		},
		toggleStatus() {
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: 'post',
				url: `/${projectId}/question-answer/toggle-status/${this.questionId}`,
			}).then(() => {
				this.isPublished = !this.isPublished
			})
		},
		updateService(array) {
			this.services = array
		},
	},
}
</script>
<style scoped>
.label {
	font-size: 15px;
	color: black;
}
.headline {
	color: #303f9f !important;
	font-size: 24px !important;
}
.underline {
	border-bottom: 3px solid #8bc34a;
	margin-top: 10px;
	width: 40px;
	display: block;
}
</style>
